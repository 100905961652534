/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #8dc9e0;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

}

.kachel-icons {
  font-size: 5vh;
  color: var(--ion-color-dark)
}

.item-trans ion-item {
  --ion-item-background: transparent !important;
}

.klein {
  font-size: 12px;
  word-break: break-all;
  display: flex;
  align-items: center;
}

.kleiner {
  font-size: 10px;
  word-break: break-all;
  display: flex;
  align-items: center;
}

.highlight_artikel {
  color: "tertiary"
}

.zweizeilig_rb>div {
  text-align: right
}


/* ion-col {border: 1px solid #000;} */

.grid>ion-row:nth-of-type(even) {

  background: var(--ion-color-light-shade);
  /* color: var( --ion-color-medium-contrast); */

}

/* .grid > ion-row:nth-child(even) > ion-col > ion-radio { 
  
  --color: var( --ion-color-medium);
 --color-checked: var(--ion-color-primary-shade )
} */

/* .grid > ion-row:nth-child(odd) > ion-col > ion-radio { 
  
  --color: var( --ion-color-medium-tint);
 --color-checked: var(--ion-color-medium-tint )
} */

ion-datetime-button::part(native) {
  padding: 0px 15px
}

ion-content {
  --background: none;
  background-image: url('../../public/assets/bg_lieferanten.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}


.datatable {
  
  white-space: nowrap;
  min-height: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  font-size: 0.8em;
}
.datatable td,
.datatable th {
  padding: 0.5rem 1rem;
  border: 1px solid black;

}
.datatable thead th {
  position: sticky;
  top: 0;
  background-color: #28ba62;
  border: 1px solid black;
  z-index:3;

}


.datatable tbody th {
  font-weight: 100;
  text-align: left;
}
.datahead {
  text-align: center;
  position: 'sticky';
/*  z-index:  2;*/
  top: 0;
}

.datacell {
  border-bottom: #000000 1px solid;
  position: relative;
}

.datacelllarge {
  min-width:250px;
  max-width:250px;
}

.datacellinput {
  font-size: 0.8em;
  min-width: 100px;
}

ion-textarea.datacellinput {
  --border: 1px solid #000000;
  --color:black;
  --placeholder-color: #ddd;
  --placeholder-opacity: 0.8;
}

ion-fab-list ion-button {
  overflow: visible;
  position: relative;
  contain: layout;
  height: 50px ;
  
}
ion-fab-list ion-button ion-label {
  position: absolute;
  right: 50px;
  min-height: 50px;
  padding-right:10px;
  padding-left:10px;
  box-shadow: var(--box-shadow);
  width: max-content;
  display: flex;
  align-items: center;
  background-color: var(--ion-color-primary-contrast);
  color: var(--ion-color-primary)
}
ion-fab-list ion-button::part(native) {
  contain: initial;
  overflow: visible;
}