/** Styling "#shadow-root" s.  https://ionicframework.com/docs/theming/css-shadow-parts */
ion-modal::part(content) {
  height: auto ;
  width: auto ;
}

ul {
  list-style-type: none ;
  padding-left: 0 ;
}
